<template>
  <div class="blog-detail-wrapper">

    <b-row class="blog-list-wrapper">
      <b-overlay
          :show="detailLoading"
          rounded="sm"
      >

        <b-col cols="12" class="d-flex align-items-center justify-content-center mb-2">
          <b-form-input size="lg" v-model="listsDetails.title" @keyup.enter="updateItem"/>
        </b-col>

        <b-col cols="12" class="d-flex align-items-center justify-content-center mb-2"
          v-if="listsDetails.series"
        >
          <!-- recent posts -->
          <div class="blog-recent-posts mt-3">
            <h6 class="section-label mb-75">
              Сериалы
            </h6>

            <b-row>
              <b-col
                  cols="3"
                     class="d-flex align-items-left justify-content-left mb-2"
                     v-for="(series,index) in listsDetails.series"
                     :key="series.id"
              >
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-link :to="{ name: 'movie-view', params: {id: series.id}}">
                      <b-img
                          :src="series.poster_preview"
                          :alt="series.poster_preview.slice(6)"
                          rounded
                          height="100"
                      />
                    </b-link>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="blog-recent-post-title">
                      <b-link :to="{ name: 'movie-view', params: {id: series.id}}" class="text-body-heading">
                        {{ series.title_ru }}
                      </b-link>
                      / {{ series.year }}
                    </h6>
                    <div>
                      <span :title="series.votes_kp">
                        {{ series.rating_kp }}
                      </span>
                      /
                      <span :title="series.votes_imdb">
                        {{ series.rating_imdb }}
                      </span>
                      (
                      <span>
                        {{ series.votes_kp }} / {{ series.votes_imdb }}
                      </span>
                      )
                    </div>
                    <span class="text-muted mb-0">
                      {{ series.title_en }}
                    </span>
                    <div class="text-muted mb-0">
                      {{ series.genres }}
                    </div>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>

          </div>
          <!--/ recent posts -->
        </b-col>
      </b-overlay>
    </b-row>

    <b-overlay
        :show="addItemLoading"
        rounded="sm"
    >
      <b-row class="mb-1">
        <b-col cols="12">
          <b-form-group
              label="Найти сериал"
              label-for="links"
          >
            <v-select
                v-model="movie"
                :options="searchMoviesOptions"
                :clearable="true"
                input-id="links"

                label="title_ru"
                :filterable="false"
                @search="fetchMovies"
            >
              <template slot="no-options">
                Начните печатать название фильма
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <b-avatar
                      size="20"
                      :src="option.poster_preview"
                      variant="primary"
                  />
                  {{ option.title_ru }} ({{ option.year }}) [{{ option.votes_kp }}]
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  <b-avatar
                      size="20"
                      :src="option.poster_preview"
                      variant="primary"
                  />
                  {{ option.title_ru }}
                </div>
              </template>
            </v-select>
          </b-form-group>

<!--          <v-select
              v-model="newMovie"
              :options="moviesOptions"
              :clearable="true"
              input-id="add-movie"
              label="title_ru"
              :filterable="false"
              @search="fetchMovies"
              debounce="2000"
          >
            <template slot="no-options">
              Начните печатать название фильма
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                <b-avatar
                    size="20"
                    :src="option.poster_preview"
                    variant="primary"
                />
                {{ option.title_ru }} ({{ option.year }}) [{{ option.votes_kp }}]
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                <b-avatar
                    size="20"
                    :src="option.poster_preview"
                    variant="primary"
                />
                {{ option.title_ru }}
              </div>
            </template>
          </v-select>-->
        </b-col>
        <b-col cols="12" class="mt-1">
          <b-button
              type="submit"
              variant="success"
              block
              @click.prevent="addMovie()"
          >
            Добавить
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>

    <!--/ blogs -->

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BCardHeader,
  BTable,
  BOverlay,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import {avatarText, badWord, kFormatter, truncateString} from '@core/utils/filter'
import store from "@/store";
import {onUnmounted, ref, watch} from "@vue/composition-api";
import topicsStoreModule from "@/views/topics/topicsStoreModule";
import movieStoreModule from "@/views/database/movieStoreModule";
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from "vue-select";
import router from "@/router";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BCardHeader,
    BTable,
    BButton,
    BOverlay,
    VueAutosuggest,
    vSelect,
  },
  data() {
    return {
      blogSidebar: {},
      disabled: 0,
      query: '',
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
  },
  setup() {
    const STORE_MODULE_NAME = 'topics'
    const MOVIES_MODULE_NAME = 'movies'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, topicsStoreModule)
    if (!store.hasModule(MOVIES_MODULE_NAME)) store.registerModule(MOVIES_MODULE_NAME, movieStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      if (store.hasModule(MOVIES_MODULE_NAME)) store.unregisterModule(MOVIES_MODULE_NAME)
    })

    const type = ref('movies')

    const listsDetails = ref([])
    const addItemLoading = ref(false)
    const detailLoading = ref(false)
    const movie = ref(null)
    const searchMoviesOptions = ref([])
    const currentReq = ref(0)
    const fetchList = (...args) => store.dispatch('topics/fetchList', ...args)
    const updateList = (...args) => store.dispatch('topics/updateList', ...args)
    const addItem = (...args) => store.dispatch('topics/addItemToList', ...args)

    const fetchListDetails = () => {
      let id = router.currentRoute.params.id
      detailLoading.value = true;

      fetchList({id: id})
          .then(response => {
            listsDetails.value = response.data.data
            detailLoading.value = false;
          })
    }

    const updateItem = () => {
      let id = router.currentRoute.params.id
      detailLoading.value = true;
      updateList({id: id, queryParams: listsDetails.value})
          .then(response => {
            console.log('TRUE')
            detailLoading.value = false;
          })
    }

    const addMovie = () => {
      let id = router.currentRoute.params.id
      addItemLoading.value = true
      addItem({id: id, queryParams: {item_id: movie.value.id}}).then(response => {
        listsDetails.value.series.push(response.data.data)
        movie.value = null
        searchMoviesOptions.value = []
        addItemLoading.value = false
      })
    }

    const fetchMovies = (search) => {
      if (search.length >= 3) {
        fetchMoviesInternal(search)
      }
    }

    const fetchMoviesInternal = (search) => {
      currentReq.value++
      let reqNumber = currentReq.value
      store.dispatch('movies/fetchMovies', {
        q: search,
        type: listsDetails.value.type,
        exclude_list_id: listsDetails.value.id,
      })
          .then(response => {
            if (reqNumber !== currentReq.value) return
            searchMoviesOptions.value = response.data.data
          })
    }

    fetchListDetails()

    return {
      listsDetails,
      avatarText,
      updateItem,
      addItemLoading,
      detailLoading,
      type,
      fetchMovies,
      movie,
      searchMoviesOptions,
      addMovie,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
